import {Translate as TranslateIcon} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    lighten,
    Paper,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setSelectedMenuItem} from "../dataSlice";
import axios from "axios";
import Api from "../Api";
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


export function AdminFacilities() {
    const [selectedRow, setSelectedRow] = useState(localStorage.getItem("selectedRow") ?? null // Load the initial value from Local Storage
    );
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let newDir = null;
    const [imageCount, setImageCount] = useState({});
    const [facilities, setFacilities] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [newTitle] = useState({});
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [dialogDeleteDir, setDialogDeleteDir] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`./data/facilities.json?t=${new Date().getTime()}`);
                setFacilities(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleFileChange = (e) => setSelectedFile(e.target.files[0]);

    const handleAddDir = useCallback(async (newDir) => {
        setLoading(true);
        await Api.facility_adddirfacility(newDir);
        setLoading(false);
        window.location.reload();
    }, []);

    const handleFileUpload = useCallback(async (facility) => {
        const formattedParentTitle = facility;
        const formData = new FormData();
        formData.append("image", selectedFile);
        setLoading(true);
        await Api.facility_uploadimagefacilities(formData, facility.guid);
        window.location.reload();
        setLoading(false);
    }, [selectedFile]);

    const handleDeleteImage = useCallback(async () => {
        setLoading(true);
        await Api.facility_deleteimagefacilities(imageToDelete);
        setLoading(false);
        window.location.reload();
    }, [imageToDelete]);

    const handleDeleteDir = useCallback(async (facilityGuid) => {
        setLoading(true);
        await Api.deletedirfacility(facilityGuid);
        setLoading(false);
        window.location.reload();
    }, []);

    const handleSaveTitle = async (facility) => {
        setLoading(true);
        await Api.facility_changefacilitytitle(facility);
        setLoading(false);
        window.location.reload();
    };

    const handleMoveUp = (facility) => {
        const index = facilities.findIndex(f => f.guid === facility.guid);
        if (index > 0) {
            const newFacilities = [...facilities];
            const temp = newFacilities[index - 1];
            newFacilities[index - 1] = facility;
            newFacilities[index] = temp;
            setFacilities(newFacilities);
        }
    };

    const handleMoveDown = (facility) => {
        const index = facilities.findIndex(f => f.guid === facility.guid);
        if (index < facilities.length - 1) {
            const newFacilities = [...facilities];
            const temp = newFacilities[index + 1];
            newFacilities[index + 1] = facility;
            newFacilities[index] = temp;
            setFacilities(newFacilities);
        }
    };

    const moveFacility = async (e, index, direction) => {
        e.preventDefault();

        const facility = facilities[index];
        const newPosition = index + direction;

        if (newPosition < 0 || newPosition >= facilities.length) {
            return;
        }

        const newFacilities = [...facilities];
        newFacilities[index] = newFacilities[newPosition];
        newFacilities[newPosition] = facility;

        setFacilities(newFacilities);

        await Api.saveFacilityOrder(newFacilities);
    };


    function getDialog() {
        return <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={imageToDelete != null}
            onClose={() => setImageToDelete(null)}
        >
            <DialogTitle>Delete Image?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this image?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setImageToDelete(null)}
                >
                    Cancel
                </Button>
                <Button
                    onClick={async () => await handleDeleteImage()}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>;
    }

    function getDialogDeleteDir() {
        return <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={dialogDeleteDir != null}
            onClose={() => setImageToDelete(null)}
        >
            <DialogTitle>Delete Section?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the section{" "}
                    <Box component="span" sx={{fontWeight: "bold"}}>
                        {dialogDeleteDir}
                    </Box>{" "}
                    and all images it contains? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setDialogDeleteDir(null)}
                >
                    Cancel
                </Button>
                <Button
                    onClick={async () => await handleDeleteDir(dialogDeleteDir)}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>;
    }

    useEffect(() => {
        localStorage.setItem("selectedRow", selectedRow); // Store the updated value to Local Storage
    }, [selectedRow]);

    useEffect(() => {
        const counts = {};
        facilities?.forEach((rootTitle) => {
            counts[rootTitle.title] = rootTitle.data.length;
        });
        setImageCount(counts);
    }, [facilities]);

    const dispatch = useDispatch();

    return <>
        {loading && <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999
            }}
        >
            <Backdrop open>
                <CircularProgress color="primary"/>
            </Backdrop>
        </Box>}
        {getDialog()}
        {getDialogDeleteDir()}
        <Paper>
            <Box sx={{p: 2}}>FACILITY</Box>
            <Grid
                container
                spacing={0}
                sx={{display: "flex", flexDirection: "column"}}
            >
                {facilities && facilities
                    .map((facility, index) => <React.Fragment key={index}>
                        <Box sx={{
                            borderBottomWidth: 1, borderBottomColor: (theme) => theme.palette.secondary.main
                        }}/>
                        <Grid
                            item
                            sx={{
                                mb: 2
                            }}
                        >
                            <Paper elevation={4} sx={{mb: 2, p: 2}}>
                                <Box sx={{
                                    my: 2, display: "flex", alignItems: "center", gap: 2
                                }}>
                                    <TextField fullWidth
                                               value={facility.title}
                                               InputProps={{
                                                   endAdornment: (<IconButton onClick={() => {
                                                       dispatch(setSelectedMenuItem({
                                                           route: "translations", key: facility.title
                                                       }));
                                                   }}>
                                                       <TranslateIcon/>
                                                   </IconButton>)
                                               }}
                                               onChange={e => {
                                                   const newFacilities = [...facilities];
                                                   const facilityIndex = newFacilities.findIndex(f => f.guid === facility.guid);
                                                   if (facilityIndex !== -1) {
                                                       newFacilities[facilityIndex] = {
                                                           ...facility, title: e.target.value
                                                       };
                                                       setFacilities(newFacilities);
                                                   }
                                               }}
                                    />
                                    <IconButton
                                        size={"small"}
                                        color="primary"
                                        onClick={async () => await handleSaveTitle(facility)}
                                    >
                                        <SaveIcon/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        color="error"
                                        onClick={async () => await setDialogDeleteDir(facility.guid)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        color="primary"
                                        onClick={(e) => {
                                            moveFacility(e, index, -1);
                                        }}
                                        disabled={index === 0}
                                    >
                                        <ArrowUpwardIcon/>
                                    </IconButton>
                                    <IconButton size={"small"} color="primary"
                                                onClick={(e) => {
                                                    moveFacility(e, index, 1);
                                                }}
                                                disabled={index === facilities.length - 1}>
                                        <ArrowDownwardIcon/>
                                    </IconButton>
                                </Box>
                                <Box sx={{
                                    my: 2, display: "flex", alignItems: "center", gap: 2
                                }}>
                                    <TextField fullWidth value={facility.sub_title}
                                               InputProps={{
                                                   endAdornment: (<IconButton onClick={() => {
                                                       dispatch(setSelectedMenuItem({
                                                           route: "translations", key: facility.title + "_sub"
                                                       }));
                                                   }}>
                                                       <TranslateIcon/>
                                                   </IconButton>)
                                               }}
                                    />
                                </Box>
                            </Paper>
                            <Box sx={{px: 2}}>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={() => setSelectedRow(selectedRow === facility.title ? null : facility.title)}
                                    endIcon={<ExpandMoreIcon/>}
                                >
                                    {`Manage images (${imageCount[facility.title] ?? 0})`}
                                </Button>
                            </Box>
                            {selectedRow === facility.title && <Box>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        my: 2,
                                        p: 2,
                                        backgroundColor: (theme) => lighten(theme.palette.background.paper, 0.1)
                                    }}
                                >
                                    <input type="file" onChange={handleFileChange}/>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => await handleFileUpload(facility)}
                                    >
                                        Upload
                                    </Button>
                                </Paper>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{mt: 2, p: 2}}
                                    component="ul"
                                    justifyContent="center"
                                    alignItems="center"
                                    direction="row"
                                    wrap="wrap"
                                    columnSpacing={{xs: 2, sm: 3, md: 4}}
                                    rowSpacing={{xs: 2, sm: 3, md: 4}}
                                >
                                    {facility?.data?.map((image, index2) => <Grid
                                        item
                                        key={`${index}-${index2}`}
                                        component="li"
                                        sx={{width: 200, height: 200}}
                                    >
                                        <Box
                                            sx={{
                                                position: "relative", width: "100%", height: "100%"
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    paddingTop: "100%",
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    backgroundImage: `url('/assets/facility_${facility.title}/${image.src}')`,
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}
                                                >
                                                    <Button
                                                        size={"small"}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => setImageToDelete(image.src)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>)}
                                </Grid>
                            </Box>}
                        </Grid>
                    </React.Fragment>)}
            </Grid>
            <Paper
                elevation={4}
                sx={{
                    display: "flex", justifyContent: "center", alignItems: "center", p: 2
                }}
            >
                <Button
                    size={"large"}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Add new facility
                </Button>
            </Paper>
            <Dialog
                open={open}
                onClose={() => setOpen(true)}
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <DialogTitle>Add new section</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={e => {
                            newDir = e.target.value;
                        }}
                        label="Enter section name"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        onClick={async () => await handleAddDir(newDir)}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    </>;
}

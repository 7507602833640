import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Button, Container, Grid, Paper, TextField, ThemeProvider, useTheme} from '@mui/material';
import {createTheme} from '@mui/system';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-shadow.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {renderToString} from 'react-dom/server';
import {icon} from "leaflet";
import {t} from "i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {theme} from "../theme";
import Api from "../Api";
import { useNavigate } from 'react-router-dom';
import {useCookies} from "react-cookie/cjs";

const markerIcon = icon({
    iconUrl: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(renderToString(<FontAwesomeIcon
        icon={faMapMarkerAlt}/>)),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
});

const address = 'Via Rodari, 2, 22100 Como CO';
const location = {
    center: [45.81283639174212, 9.083350210371503], zoom: 20
};
const isMobile = window.matchMedia("(max-width:1024px)").matches;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4), paddingBottom: theme.spacing(0),
    }, form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiTextField-root': {
            margin: theme.spacing(1), width: '100%',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(2),
        },
        '& form': {
            border: 'none',
        },
    }, mapContainer: {
        height: '300px', width: '100%'
    }, gridItem: {
        paddingTop: isMobile ? '0px !important' : undefined,
    },
}));

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "2rem",
        backgroundColor: "",
    }, title: {
        fontWeight: "bold",
        marginBottom: "1rem",
        marginTop: "1rem",
        fontSize: isMobile ? "2rem" : "3rem",
        textAlign: "center",
    }, subtitle: {
        fontSize: "1.2rem", marginBottom: "2rem", marginTop: "1rem", textAlign: "center",
    }, list: {
        width: "100%", maxWidth: "360px", marginBottom: "1rem", textAlign: "center",
    }, listItem: {
        paddingTop: "0.5rem", paddingBottom: "0.5rem",
    }, listItemIcon: {
        minWidth: "24px", marginRight: "1rem",
    }, socialIcon: {
        fontSize: "2rem", marginRight: "1rem", color: "#000", transition: "color 0.2s ease-in-out", "&:hover": {
            color: "#0077b5",
        },
    },
};

function ContactPage() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [cookie, setCookie] = useCookies(["preferredLanguage"]);
    const [language, setLanguage] = useState(cookie.preferredLanguage ?? "en");

    useTheme();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        await Api.sendemail(name, email, phone, message);
        if(language === 'en'){
            navigate('/thank-you-form');
        }
        else{
            navigate('/grazie-form');
        }
    };

    const lightTheme = createTheme({
        ...theme, palette: {
            ...theme.palette, mode: "light",
        }, components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: 'black',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: "rgba(0, 0, 0, .8)", // set the color for the label
                    },
                },
            },
        },
    });

    return <ThemeProvider theme={lightTheme}>
        <>
            <div className={classes.root} id={"welcome"}>
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="center">
                        {/* left column with contact information */}
                        <Grid item xs={12} md={6}>
                            <Paper elevation={5} style={styles.root} id="contact">
                                <div style={styles.root} id="contact">
                                    <h2 style={styles.title}>{t("contact.title")}</h2>
                                    <h4 style={styles.subtitle}>{t("contact.subtitle")}</h4>
                                    <ul style={styles.list}>
                                        <li style={styles.listItem}>
                                      <span style={styles.listItemIcon}>
                                        <LocationOnIcon/>
                                      </span>
                                            {t("contact.address")}
                                        </li>
                                        <li style={styles.listItem}>
                                      <span style={styles.listItemIcon}>
                                        <PhoneIcon/>
                                      </span>
                                            {t("contact.phone")}
                                        </li>
                                        <li style={styles.listItem}>
                                      <span style={styles.listItemIcon}>
                                        <EmailIcon/>
                                      </span>
                                            {t("contact.email")}
                                        </li>
                                    </ul>
                                    <h4 style={styles.subtitle}>{t("contact.socialmedia")}</h4>
                                    <div>
                                        <a href={t("contact.facebook.url")}>
                                            <FacebookIcon style={{color: 'white'}}/>
                                        </a>
                                        <a href={t("contact.instagram.url")}>
                                            <InstagramIcon style={{color: 'white'}}/>
                                        </a>
                                        <a href={t("contact.linkedin.url")}>
                                            <LinkedInIcon style={{color: 'white'}}/>
                                        </a>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                        {/* right column with contact form */}
                        <Grid item xs={12} md={6} className={classes.gridItem}>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <TextField
                                    label="Tel"
                                    variant="outlined"
                                    type="tel"
                                    required
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <TextField
                                    label="Message"
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    required
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    style={{backgroundColor: 'black', color: 'white'}}
                                    type="submit"
                                    onClick={async () => {
                                    }}
                                >
                                    {t("contact.submit")}
                                </Button>

                            </form>
                        </Grid>
                    </Grid>
                </Container>
                <MapContainer
                    center={location.center}
                    zoom={location.zoom}
                    scrollWheelZoom={false}
                    style={{height: '500px', width: '100%'}}
                    attributionControl={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    />
                    <Marker position={location.center} icon={markerIcon}>
                        <Popup>{address}</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </>
    </ThemeProvider>
}

export default ContactPage;

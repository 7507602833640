import {useLocation} from "react-router-dom";
import Navbar from "./Navbar";
import React from "react";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {type RootState} from "../Store";
import ScrollToWelcome from "./ScrollToWelcome";
import {type IRoute} from "../dataSlice";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

const Header: React.FC = () => {
    const location = useLocation();
    const state = useSelector((state: RootState) => state.data);
    const {t} = useTranslation();

    function getBackground(location: { pathname: string }): JSX.Element | null {
        const matchingRoute = state.routes.find((route: IRoute) => route.key.startsWith(location.pathname)) || state.routes[0];
        switch (true) {
            case matchingRoute?.type === "video":
                return <video
                    className="videobg"
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="metadata"
                >
                    <source
                        src={matchingRoute?.media}
                        type="video/mp4"
                    />
                </video>;
            case matchingRoute?.type === "img":
                return <Box
                    sx={{
                        backgroundImage: `url(/media/${matchingRoute?.media})`,
                        position: "relative",
                        zIndex: 2,
                        transition: "1s opacity",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    />
                    <Typography
                        variant="h3"
                        sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: "bolder",
                            fontSize: {sx: "10px", md: "30px"},
                            letterSpacing: "2px",
                            textTransform: "capitalize",
                            display: "block",
                            p: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "block",
                                position: "relative",
                                fontStyle: "italic",
                                px: 1,
                                top: 0,
                                fontSize: {
                                    xs: "1.5rem",
                                    lg: "3rem",
                                }
                            }}
                        >
                            {t("header." + matchingRoute?.key.replace("/", "")).toLowerCase()}
                        </Box>
                        <Box
                            sx={{
                                display: "block",
                                position: "relative",
                                fontStyle: "italic",
                                mt: 1,
                                px: 1,
                                top: 0,
                                fontSize: {
                                    xs: ".75rem",
                                    lg: "1rem",
                                }
                            }}
                        >
                            {t("header.sub." + matchingRoute?.key.replace("/", "")).toLowerCase()}
                        </Box>
                    </Typography>

                </Box>;
            default:
                return null;
        }
    }

    return <div id="headerarea" className="relative">
        <div className="absolute z-10 uppercase w-full p-4 lg:p-10">
            <Navbar/>
        </div>
        {getBackground(location)}
        <ScrollToWelcome/>
    </div>;
};

export default Header;

import React, {useEffect, useRef} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {useTranslation} from 'react-i18next';
import Services from './Services';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import About2 from "./About2";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const {t} = useTranslation();
    const isMobile = useMediaQuery('(max-width:1024px)');

    const welcomeMessages = [...Array(100)].filter(
        (_, i) => t(`home.${i + 1}.item`) !== `home.${i + 1}.item`
    );

    const containerRef = useRef(null);
    const articleRefs = useRef([]);

    const addToRefs = el => {
        if (el && !articleRefs.current.includes(el)) {
            articleRefs.current.push(el);
        }
    };

    useEffect(() =>
        articleRefs.current.forEach((article, index) =>
            gsap.from(article, {
                duration: 1.2,
                opacity: 0,
                x: -100, // change this value to control the sliding distance
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: article,
                    start: 'top 80%',
                    once: true,
                },
            })), []);


    return <Box
        sx={{
            textAlign: 'center',
            maxWidth: isMobile ? '99vw' : '60vw',
            margin: 'auto',
        }}
        id={'welcome'}
    >
        <div ref={containerRef} className={'gsap-container'}>
            <div className={'ch1 uppercase mt-6 text-center m-auto pt-8'}>
                {t(`home.title`)}
            </div>
            <div className={'h2'}>{t(`home.subtitle`)}</div>
            {welcomeMessages.map((_, i, arr) =>
                <React.Fragment key={i}>
                    <article ref={addToRefs}>
                        <Box
                            component="p"
                            sx={{
                                textAlign: isMobile ? 'center' : 'left',
                                ...(i !== arr.length - 1 && {mb: 2}),
                            }}
                        >
                            {t(`home.${i + 1}.item`)}
                        </Box>
                    </article>
                    {i !== arr.length - 1 && <div className="divider"/>}
                </React.Fragment>)}
        </div>
        <Services/>
        <About2/>
        <div><br/></div>
    </Box>;
};

export default Home;

import {ThemeProvider} from "@mui/material/styles";
import React from "react";
import {createRoot} from "react-dom/client";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import i18n from "./i18n";
import {store} from "./Store";
import {theme} from "./theme";

const baseUrl = document.getElementsByTagName("base")[0]?.getAttribute("href") ?? "";
const rootElement = document.getElementById("root") as any;
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </Provider>
        </I18nextProvider>
    </BrowserRouter>);
import {useMediaQuery, useTheme} from "@mui/material";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import Api from "../Api";
import {AdminRoutingRender} from "./AdminRoutingRender";

const AdminRoutes = () => {
    const [images, setImages] = useState<any>();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [newTitle] = useState<Record<string, any>>({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`./data/routes.json`);
                setImages(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleFileChange = (e: any) => setSelectedFile(e.target.files[0]);

    const handleAddDir = useCallback(async (newDir: any) => {
        setLoading(true);
        await Api.facility_adddirfacility(newDir);
        setLoading(false);
        window.location.reload();
    }, []);

    const handleFileUpload = useCallback(async (parentTitle: any, key: any) => {
        const formattedParentTitle = parentTitle;
        const formData = new FormData();
        formData.append("image", selectedFile);
        setLoading(true);
        await Api.routes_uploadimage(formData, key);
        window.location.reload();
        setLoading(false);
    }, [selectedFile]);

    const handleDeleteImage = useCallback(async () => {
        setLoading(true);
        await Api.routes_deleteimage(imageToDelete);
        setLoading(false);
        window.location.reload();
    }, [imageToDelete]);

    const handleDeleteDir = useCallback(async (dirToDelete: any) => {
        setLoading(true);
        await Api.deletedir(dirToDelete);
        setLoading(false);
        window.location.reload();
    }, []);

    const handleSaveTitle = useCallback(async (route: any) => {
        setLoading(true);
        await Api.facility_changefacilitytitle(route);
        setLoading(false);
        window.location.reload();
    }, [newTitle]);

    return AdminRoutingRender(
        loading,
        imageToDelete,
        setImageToDelete,
        handleDeleteImage,
        isMobile,
        images,
        newTitle,
        handleSaveTitle,
        handleFileChange,
        handleFileUpload,
        handleAddDir,
        handleDeleteDir
    );
};

export default AdminRoutes;

import {Add, Delete, Edit} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect} from "react";

interface DataTableProps<T> {
    data: T[];
    columns: Array<Column<T>>;
    onSave: (data: T) => void;
    onNew: (key: any) => T;
    onDelete: (data: T | null) => void;
    highlightedRow?: { key: any, route: any };
}

interface Column<T> {
    readonly?: boolean;
    colspan: number;
    key: keyof T;
    label: string;
}

export function AiTable<T extends object>({
                                              data,
                                              columns,
                                              onSave,
                                              onNew,
                                              onDelete,
                                              highlightedRow
                                          }: DataTableProps<T>) {
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [editedData, setEditedData] = React.useState<T | null>(null);
    const [deleteData, setDeleteData] = React.useState<T | null>(null);
    const handleSaveClick = async () => {
        if (editedData != null) {
            try {
                await onSave(editedData);
                setEditDialogOpen(false);
                setEditedData(null);
            } catch (e) {
            }
        }
    };

    const handleCancelClick = () => {
        setEditDialogOpen(false);
        setEditedData(null);
    };

    const handleFieldChange = (key: keyof T, value: any) => {
        if (editedData != null) {
            setEditedData((prevData: any) => ({
                ...prevData,
                [key]: value
            }));
        }
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleEditClick = (row: T) => {
        setEditedData(row);
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (row: T) => {
        setDeleteData(row);
    };

    const handleDeleteConfirm = async () => {
        if (deleteData != null) {
            await onDelete(deleteData);
            setDeleteData(null);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteData(null);
    };

    const renderTableCell = (row: any, col: any) => {
        const value = row[col.key];
        if (typeof value === "boolean") {
            return value ? "Yes" : "No";
        }
        return value;
    };

    useEffect(() => {
        if (highlightedRow?.key) {
            const foundItem = data.find((item) => (item as any).key?.toLowerCase() === highlightedRow?.key?.toLowerCase());
            if (foundItem == null) {
                handleEditClick(onNew(highlightedRow?.key));
            }
        }
    }, [highlightedRow]);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={deleteData != null}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>Confermi l'eliminazione?</DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {highlightedRow?.key && <Box sx={{textAlign: "center"}}>
                <Button onClick={() => {
                    window.location.reload();
                }}>torna indietro</Button>
            </Box>}
            <TableContainer component={Paper} id={"admin"}>
                <Table style={{tableLayout: "fixed"}}>
                    <TableHead>
                        <TableRow>
                            {
                                columns?.map((col: any) => (
                                    <TableCell colSpan={col.colspan} key={col.key}>
                                        {col.label}
                                    </TableCell>
                                ))}
                            <TableCell colSpan={1} sx={{textAlign: "right"}}>
                                <IconButton onClick={() => handleEditClick(onNew(""))}>
                                    <Add/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row: any) => {
                            return (
                                <TableRow
                                    key={JSON.stringify(row)}
                                    sx={
                                        highlightedRow?.key?.toLowerCase() === row.key?.toLowerCase()
                                            ? {
                                                borderColor: (theme: Theme) => theme.palette.secondary.main,
                                                borderWidth: 10
                                            }
                                            : {}
                                    }
                                >
                                    {columns?.map((col: any) => (
                                        <TableCell
                                            colSpan={col.colspan}
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap"
                                            }}
                                            key={col.key}
                                        >
                                            {renderTableCell(row, col)}
                                        </TableCell>
                                    ))}
                                    <TableCell colSpan={1} sx={{textAlign: "right"}}>
                                        <IconButton onClick={() => handleEditClick(row)}>
                                            <Edit/>
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(row)}>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                fullScreen={fullScreen}
                open={editDialogOpen}
                onClose={handleCancelClick}
            >
                <DialogTitle>Edit Row</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please edit the fields below:</DialogContentText>
                    {editedData != null &&
                        columns.map((col) => (
                            <div key={col.key as string}>
                                <TextField
                                    InputProps={{
                                        readOnly: col.readonly
                                    }}
                                    multiline={true}
                                    label={col.label}
                                    value={editedData[col.key] as any}
                                    onChange={(e) => {
                                        handleFieldChange(col.key, e.target.value);
                                    }}
                                    fullWidth
                                    margin="normal"
                                ></TextField>
                            </div>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <Button onClick={handleSaveClick}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useMediaQuery} from "@mui/material";
import {Box} from "@mui/system";
import {theme} from "../theme";

const languageNames: Record<string, string> = {
    en: "English",
    it: "Italiano"
};

const languageIcons: Record<string, string> = {
    en: "https://hatscripts.github.io/circle-flags/flags/us.svg",
    it: "https://hatscripts.github.io/circle-flags/flags/it.svg"
};

interface LanguageSwitcherProps {
    layout: "row" | "column";
}

function LanguageSwitcher({layout}: LanguageSwitcherProps) {
    const [, setCookie] = useCookies(["preferredLanguage"]);
    const handleLanguageChange = (newLanguage: string) => {
        setCookie("preferredLanguage", newLanguage, {path: "/"});
        i18n.changeLanguage(newLanguage);
    };

    const {i18n} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: layout === "row" ? "row" : "column",
                alignItems: "center",
            }}
        >
            {Object.keys(languageNames).map((lang) => {
                const languageCode = lang;
                return (
                    <Box
                        key={languageCode}
                        component="button"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: layout === "row" ? "8px" : 0,
                            marginBottom: layout === "column" ? "8px" : 0,
                            color: i18n.language === languageCode ? "white" : "primary.main",
                        }}
                        onClick={() => {
                            handleLanguageChange(languageCode);
                        }}
                    >
                        <img
                            src={languageIcons[languageCode]}
                            alt={`${languageNames[languageCode]} flag`}
                            className="w-4 h-4 mr-1 rounded-full"
                        />
                        <span>{languageNames[languageCode]}</span>
                    </Box>
                );
            })}
        </Box>
    );
}

export default LanguageSwitcher;

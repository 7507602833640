import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleDown} from "@fortawesome/free-solid-svg-icons";
import "animate.css";

const ScrollToWelcome: React.FC = () => {
    const scrollFunction = () => {
        const welcome = document.getElementById("welcome");
        if (welcome != null) {
            window.scrollTo({
                behavior: "smooth",
                top: welcome.offsetTop,
            });
        }
    };

    return (
        <div onClick={scrollFunction} style={{position: "relative"}}>
            <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                className=""
                style={{
                    cursor: "pointer",
                    opacity: 0.75,
                    position: "absolute",
                    bottom: 0,
                    marginBottom: "30px",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    zIndex: 10
                }}
                color="white"
                size="3x"
            />
        </div>
    );
};

export default ScrollToWelcome;

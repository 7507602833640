import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Drawer, IconButton, List, ListItem, ListItemText, type Theme, Toolbar, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Api from "../Api";
import {setSelectedMenuItem} from "../dataSlice";
import {RootState} from "../Store";
import {AiTable} from "./AiEdit";
import AdminRoutes from "./AdminRoutes";
import {AdminGallery} from "./AdminGallery";
import {AdminFacilities} from "./AdminFacilities.jsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        list: {
            width: 250,
            backgroundColor: theme.palette.background.paper
        }
    })
);

interface Lang {
    key: string;
    it: string;
    en: number;
}

const initialData: Lang[] = [];

const Admin = React.memo(function () {
    const [data, setData] = useState(initialData);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const selectedMenuItem = useSelector((state: RootState) => state.data.selectedMenuItem);

    const classes = useStyles();
    const handleDrawerOpen = () => setIsDrawerOpen(true);

    const handleDrawerClose = () => setIsDrawerOpen(false);

    const handleMenuItemClick = (menuItem: any) => {
        dispatch(setSelectedMenuItem(menuItem));
        localStorage.setItem("selectedMenuItem", JSON.stringify(menuItem));
        handleDrawerClose();
    };

    const handleRefresh = async () => {
        await Api.gallery_refresh();
        window.location.reload();
    };

    useEffect(() => {
        const storedSelectedMenuItem = localStorage.getItem("selectedMenuItem");
        if (storedSelectedMenuItem) {
            try {
                dispatch(setSelectedMenuItem(JSON.parse(storedSelectedMenuItem)));
            } catch (e) {

            }
        }
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const json = await Api.langall();
                setData(json);
            } catch (error) {
                console.error(error);
            }
        };
        (async () => await loadData())();
    }, []);

    return <>
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap>
                    Il medeghino
                </Typography>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
                    <MenuIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
        <Toolbar/>
        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
            <List className={classes.list}>
                <ListItem
                    button
                    selected={selectedMenuItem.route === "translations"}
                    onClick={() => handleMenuItemClick({route: "translations"})}
                >
                    <ListItemText primary="Translations"/>
                </ListItem>
                <ListItem
                    button
                    selected={selectedMenuItem.route === "routing"}
                    onClick={() => handleMenuItemClick({route: "routing"})}
                >
                    <ListItemText primary="Routing"/>
                </ListItem>
                <ListItem
                    button
                    selected={selectedMenuItem.route === "gallery"}
                    onClick={() => handleMenuItemClick({route: "gallery"})}
                >
                    <ListItemText primary="Gallery"/>
                </ListItem>
                <ListItem
                    button
                    selected={selectedMenuItem.route === "facilities"}
                    onClick={() => handleMenuItemClick({route: "facilities"})}
                >
                    <ListItemText primary="Facilities"/>
                </ListItem>
                <ListItem
                    button
                    onClick={async () => {
                        await handleRefresh();
                    }}
                >
                    <ListItemText primary="Refresh"/>
                </ListItem>
            </List>
        </Drawer>
        {selectedMenuItem.route === "translations" && <AiTable<Lang>
            data={data}
            onNew={(key) => ({key: key} as Lang)}
            onDelete={async (row: any) => {
                await Api.deleteadminlang(row as Lang);
                const newData = data.filter(p => p.key !== row.key);
                setData(newData);
            }}
            columns={[
                {key: "key", label: "key", colspan: 1, readonly: false},
                {key: "en", label: "en", colspan: 2},
                {key: "it", label: "it", colspan: 2}
            ]}
            onSave={async (obj: Lang) => {
                const existingObj = data.find(p => p.key === obj.key);
                if (existingObj != null) {
                    const newData = data.map(p => p.key === obj.key ? obj : p);
                    setData(newData);
                } else {
                    const newData = [...data, obj];
                    setData(newData);
                }
                await Api.saveadminlang(obj);
            }}
            highlightedRow={{key: selectedMenuItem.key, route: selectedMenuItem.route}}
        />}
        {selectedMenuItem.route === "routing" && <AdminRoutes/>}
        {selectedMenuItem.route === "gallery" && <AdminGallery/>}
        {selectedMenuItem.route === "facilities" && <AdminFacilities/>}
    </>;
});

export default Admin;

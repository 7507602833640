import React, {Fragment, memo, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useTranslation} from "react-i18next";
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {routes} from "../dataSlice";
import Box from "@mui/material/Box";

library.add(faUtensils);
library.add(faPerson);
library.add(faUserTie);
library.add(faPeopleGroup);

const ServiceCard = ({title, icon, description}: any) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const iconObject = {prefix: "fas", iconName: icon};

    return (
        <div
            className="p-4 md:p-6 lg:border-2 transition-shadow duration-300 ease-in-out hover:shadow-md"
            onMouseEnter={() => {
                setIsHovering(true);
            }}
            onMouseLeave={() => {
                setIsHovering(false);
            }}
        >
            <div className="flex justify-center items-center">
                <FontAwesomeIcon
                    icon={iconObject as IconProp}
                    className={`h-12 w-12 text-gray-400 ${
                        isHovering ? "animate-bounce" : ""
                    }`}
                />
            </div>
            <h3 className="mt-4 md:mt-6 text-lg md:text-xl font-medium text-gray-900">
                {title}
            </h3>
            <p className="mt-2 text-gray-600">{description}</p>
        </div>
    );
};

export default memo(function Services() {
    const {t} = useTranslation();

    const services = [...Array(100)].filter(
        (_, i) => t(`service.${i + 1}`) !== `service.${i + 1}`
    );

    return (
        <div className="pb-8 md:pb-16">
            <div className="max-w-7xl mx-auto">
                <div className={"h2"}>{t("home.services")}</div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
                    {services.map((_, i, arr) => {
                        return (
                            <ServiceCard
                                key={i}
                                title={t(`service.${i + 1}`)}
                                icon={t(`service.${i + 1}.icon`)}
                                description={t(`service.${i + 1}.desc`)}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
